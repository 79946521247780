import React from "react";
import { NextButton } from "../NextButton";

import SEO from "../seo";
import {
  MixdownWrapper,
  LeftWrapper,
  MixdownTitle,
  Description,
  EngineerPhoto,
  EngineerName,
  SmallText,
  ScrollWrapper,
  Stack,
} from "../../StyledComponents/mixdown.styles";

const MixdownEngineersMobile = ({ data }) => {
  const { titleLeft, titleRight, offeredServices, mixdownEngineers } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

  if (!data) return null;

  return (
    <>
      <SEO title="SPANDAU20 Mixdown" />
      {/* <FixedHeaderButtons justifyContent="flex-end">
        <NextButton url={"/mixdown/costs"} />
      </FixedHeaderButtons>
      <MixdownWrapper gap={"48px"} style={{ marginTop: 80 }}> */}
      <MixdownWrapper gap={"48px"}>
        <NextButton url={"/mixdown/costs"} />

        <Stack gap={"20px"}>
          <MixdownTitle>{titleRight}</MixdownTitle>
          <Description style={{ fontSize: 12, lineHeight: 1.25 }}>
            {offeredServices}
          </Description>
        </Stack>

        <LeftWrapper>
          <Stack gap={"24px"}>
            <MixdownTitle>{titleLeft}</MixdownTitle>
            <ScrollWrapper gap={"24px"}>
              {mixdownEngineers?.map((eng, idx) => (
                <div key={`${idx} - ${eng.name}`}>
                  <EngineerName>{eng.name}</EngineerName>
                  <SmallText style={{ fontSize: 12, lineHeight: 1.25 }}>
                    {eng.artistDescription}
                  </SmallText>
                  <EngineerPhoto src={`/${eng.photo}`} />
                </div>
              ))}
            </ScrollWrapper>
          </Stack>
        </LeftWrapper>
      </MixdownWrapper>
    </>
  );
};

export default MixdownEngineersMobile;
