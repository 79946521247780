import React from "react";
import { graphql } from "gatsby";

import { useWindowSize } from "../../lib/useWindowSize";
import { screenSizes } from "../../styles/variables";
import { NextButton } from "../../components/NextButton";
import MixdownEngineersMobile from "../../components/mixdownMobilePages/EngineersMobile";

import SEO from "../../components/seo";
import {
  ScrollWrapper,
  MixdownTitle,
  RightWrapper,
  Description,
  EngineerContainer,
  EngineerPhoto,
  EngineerBio,
  EngineerName,
  SmallText,
  MixdownWrapperWeb,
  Stack,
  ArrowImage,
} from "../../StyledComponents/mixdown.styles";

const MixdownEngineers = ({ data }) => {
  const { width } = useWindowSize();
  const { titleLeft, titleRight, offeredServices, mixdownEngineers } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

  const isBelowTablet = width <= screenSizes.tablet;

  if (!data) return null;

  return (
    <>
      <SEO title="SPANDAU20 Mixdown" />
      {!isBelowTablet && mixdownEngineers.length >= 2 ? <ArrowImage /> : null}
      <MixdownWrapperWeb>
        {!isBelowTablet ? (
          <>
            <Stack>
              <MixdownTitle>{titleLeft}</MixdownTitle>
              <ScrollWrapper>
                <Stack>
                  {mixdownEngineers?.map((eng, idx) => (
                    <EngineerContainer key={`${idx} - ${eng.name}`}>
                      <EngineerBio>
                        <EngineerName>{eng.name}</EngineerName>
                        <SmallText>{eng.artistDescription}</SmallText>
                      </EngineerBio>
                      <EngineerPhoto src={`/${eng.photo}`} />
                    </EngineerContainer>
                  ))}
                </Stack>
              </ScrollWrapper>
            </Stack>
            <RightWrapper>
              <div>
                <MixdownTitle>{titleRight}</MixdownTitle>
                <Description>{offeredServices}</Description>
              </div>
              <NextButton url={"/mixdown/costs"} />
            </RightWrapper>
          </>
        ) : (
          <MixdownEngineersMobile data={data} />
        )}
      </MixdownWrapperWeb>
    </>
  );
};

export default MixdownEngineers;

export const query = graphql`
  query MixdownEngineersQuery {
    allFile(filter: { sourceInstanceName: { eq: "mixdownEngineers" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              titleLeft
              titleRight
              offeredServices
              mixdownEngineers {
                name
                artistDescription
                photo
              }
            }
          }
        }
      }
    }
  }
`;
